html {
	/* font-size: 62.5%;  62.5% of 16px = 10px */
	background-color: #fff;
}

html,
body {
	font-family: 'Roboto', sans-serif;
}

strong {
	font-weight: 500 !important;
}

a.link {
	color: #00afb9;
	text-decoration: underline;
	transition: color 0.1s ease-out;
}

a.MuiFab-circular {
	z-index: 10!important;
}

a.link:hover,
a.link:active,
a.link:focus {
	color: #178b92;
}

ul {
	list-style-type: none;
}

ul,
li {
	margin: 0;
	padding: 0;
}

p {
	font-size: 0.85rem;
	margin: 0 0 0.75em;
}

p.small,
span.small,
a.small {
	font-size: 0.83rem;
}

p.large,
span.large,
a.large {
	font-size: 1rem;
}

p.light,
span.light,
a.light {
	color: #757575;
}

p.red,
span.red,
a.red {
	color: #178b92;
}

/* --- Font style css --- */

.font-italic {
	font-style: italic;
}
.secondary-color {
	color: #ec407a !important;
}

/* --- Font style css --- */

/**
 * annoying yellow bg on autofilled inputs
 * @see https://github.com/mui-org/material-ui/issues/283
 */

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
	transition: background-color 5000s ease-in-out 0s, color 5000s ease-in-out 0s;
	transition-delay: background-color 5000s, color 5000s;
}

/* input:-webkit-autofill {
	-webkit-box-shadow: 0 0 0 30px #FFFFFF inset;
} */

/* Helpers */

.link {
	cursor: pointer;
}

.underline {
	text-decoration: underline;
}

.hide-text {
	text-indent: 100%;
	white-space: nowrap;
	overflow: hidden;
}

.no-margin {
	margin: 0;
}

.float-left {
	float: left;
}

.float-right {
	float: right;
}

.centered {
	text-align: center;
}

.nowrap {
	white-space: nowrap;
}

hr {
	border-bottom: 1px solid #ddd;
	border-left: 0;
	border-right: 0;
	border-top: 0;
	margin: 3em 0;
}

hr.base {
	margin: 1.5em 0;
}

hr.half-width {
	width: 50%;
}

hr.quarter-width {
	width: 25%;
}

.table {
	margin: 0;
	background-color: transparent;
}

.table td {
	overflow: hidden;
}

/* Spacing */

.padding0 {
	padding: 0 !important;
}

.padding-normal {
	padding: 1.5em;
}

.padding-top {
	padding-top: 1.5em;
}

.space0 {
	margin: 0 !important;
}

.space-top {
	margin-top: 1.5em;
}

.space-top-large {
	margin-top: 3em;
}

.space-top-small {
	margin-top: 0.75em;
}

.space-top-smallest {
	margin-top: 0.5em;
}
.space-top-auto {
	margin-top: auto;
}

.space-bottom {
	margin-bottom: 1.5em;
}

.space-bottom-large {
	margin-bottom: 3em;
}

.space-bottom-small {
	margin-bottom: 0.75em;
}

.space-bottom-smallest {
	margin-bottom: 0.5em;
}

.space-right {
	margin-right: 1.5em;
}

.space-right-large {
	margin-right: 3em;
}

.space-right-small {
	margin-right: 0.75em;
}

.space-right-smallest {
	margin-right: 0.5em;
}

.space-right-auto {
	margin-right: auto !important;
}

.space-left-auto {
	margin-left: auto;
}
.space-left {
	margin-left: 1.5em;
}

.space-left-large {
	margin-left: 3em;
}

.space-left-small {
	margin-left: 0.75em;
}

.space-left-smallest {
	margin-left: 0.5em;
}

.space-x-auto {
	margin: 0 auto !important;
}

/* Containers */

#root {
	/* background-color: #f8f8f8; */
	background-color: #fff;
	-webkit-overflow-scrolling: touch;
}

.fullwidth {
	width: 100%;
}

.fitContent {
	width: fit-content;
}

.container {
	position: relative;
}

.flex {
	display: flex;
}

.flex.collapse-small {
	display: block;
}

.align-center {
	align-items: center;
}

.align-start {
	align-items: flex-start;
}

.align-end {
	align-items: flex-end;
}

.align-unset {
	align-items: unset;
}

.flex.direction-row {
	flex-direction: row;
}

.flex.direction-column {
	flex-direction: column;
}

.direct-column-reverse {
	flex-direction: column-reverse;
}

.space-between {
	justify-content: space-between;
}

.justify-center {
	justify-content: center;
}

.justify-space-between {
	justify-content: space-between;
}

.flex-container {
	display: flex;
	flex-wrap: wrap;
}

.flex-container .flex-grow {
	flex-grow: 1;
}

.container::after {
	content: '';
	clear: both;
	display: table;
}

/* Columns */

.column-quarter {
	display: block;
	width: auto;
	padding-right: 0;
}

.column-fixed {
	display: block;
	width: auto;
	padding-right: 0;
}

.nobordercell {
	border-bottom: none;
}

/* Print Styles */

body.print {
	background-color: #fff;
	height: auto;
}

body.print #root {
	background-color: #fff;
	overflow: visible;
	height: auto;
	min-height: 0;
}

/* Media Queries */

@media print {
	/* html,
	body {
		font-size: 0.8em;
		background-color: #fff;
	}

	#root {
		overflow: none;
		background-color: #fff;
	} */
}

@media screen and (min-width: 600px) {
	h1 {
		font-size: 1.5rem;
	}
	p.large,
	span.large,
	a.large {
		font-size: 1.2rem;
	}
	.flex.collapse-small {
		display: flex;
	}
}

@media screen and (min-width: 900px) {
	p {
		font-size: 0.925rem;
	}
	p.small,
	span.small,
	a.small {
		font-size: 0.9rem;
	}
	/* .block {
		padding-left: 3em;
		padding-right: 3em;
	} */
	.column-quarter {
		float: left;
		width: 25%;
		padding-right: 20px;
	}
	.column-fixed {
		float: left;
		/* width: 25%; */
		padding-right: 20px;
	}
}
